<script>
/* eslint-disable vue/no-v-html */
import FcmDate from "@components/diversos/FcmDate";
import * as FcmOnCall from "@src/services/FcmOnCall";
import {appConfig} from '@src/config';
const Emitter = require('tiny-emitter');
const emitter = new Emitter();
import status from "@common/status";
import FcmTimer from "@components/diversos/FcmTime";

import cloneDeep from "lodash/cloneDeep";

FcmOnCall.setConfig(appConfig);

// https://sistema.volan.app.br/response/volan/qaAnestesia?isDark=1&responseId=2FYtz6KOx59Q57ooxKBLNo
// https://sistema.volan.app.br/response/[companyId]/[slug]?isDark=1&responseId=[responseId]


if (window?.webkit?.messageHandlers?.formActionsHandler) {
  window.webkit.messageHandlers.formActionsHandler.postMessage({
    action: 'debug',
    data: 'jsLoading',
  });
}

function parseQuery(queryString) {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

window.jwFormDataLoaded = (args) => {
  emitter.emit('sendDataToJs', args);
};

export default {
  components: {FcmTimer, FcmDate},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      default: ()=>null,
    },
  },
  data(vm) {
    return {
      form: {},
      valueId: null,
      tryingToUpdate: false,
      connectionError: false,
      FormInputType: status.FormInputType,
      FormLinkType: status.FormLinkType,
      response: {},
      rules: {
        ...vm.$validateRules(),
      },
      responseSend: false,
      isLoading: true,
      extraData: {},
      links: null,
      isNew: false,
      userAuth: {},
      hasDataLoad: false,
      promiseDataLoad: null,
      promiseDataLoadResolve: null,
      isDebug: false,
      formCompanyId: '',
      slug: '',
      versionNumber: process.env.VUE_APP_VERSION_NUMBER,
      version: process.env.VUE_APP_VERSION,
      query: {},
      info: {},
      logoUrl: null,
      extraDataMobile: {},
      errorMessage: '',
      timeOver: false,
    }
  },
  computed: {
    canEdit() {
      return this.isNew || this.form?.canEditResponse;
    }
  },
  watch: {
    info(value, oldValue) {
      if(value!==oldValue) {

        this.$emit('changeCompanyInfo', {
          images: value?.data?.info?.companyImages || null,
          name: value?.data?.extraData?.companyName || null,
          id: value?.data?.extraData?.formCompanyId || null,
        });
      }
    },
    isLoading(value, oldValue) {
      if(value!==oldValue && !value) {
        this.$emit('finishLoading');
      }
    }
  },
  async mounted() {
    let params = window.location.pathname.split('/').filter((a)=>a);
    this.formCompanyId = params?.[1] || '';
    this.slug = params?.[2] || '';

    this.query = parseQuery(window.location.search);
    if(this.query.hasDataLoad) {
      this.hasDataLoad = JSON.parse(this.query.hasDataLoad) || false;
    }
    this.valueId = this.query?.responseId || null;
    this.promiseDataLoad = new Promise((resolve)=>{
      this.promiseDataLoadResolve = resolve;
    });
    this.emitToMobile('debug', 'mounted');
    // if(this.$route.query.responseId) {
    //   this.valueId = this.$route.query?.responseId || null;
    // }
    // if(this.$route.query.hasDataLoad) {
    //   this.hasDataLoad = JSON.parse(this.$route.query?.hasDataLoad) || false;
    // }
    // if(this.$route.query.isDebug) {
    //   this.isDebug = JSON.parse(this.$route.query?.isDebug) || false;
    // }
    emitter.on('sendDataToJs', (args) => {
      this.emitToMobile('debug', 'receivedData: '+this.isDebug?JSON.stringify(args):'No debug');
      this.processData(args);
      this.promiseDataLoadResolve?.();
      this.promiseDataLoadResolve = null;
    });
    if(window.jwFormData) {
      this.processData(window.jwFormData);
      this.promiseDataLoadResolve?.();
      this.promiseDataLoadResolve = null;
    }

    await this.loadForm();
  },
  destroyed() {
    emitter.off('sendDataToJs');
  },
  methods: {
    tryApplyExtraDataItem(response, data) {
      if(!data) {
        return;
      }
      if((response.type===this.FormInputType.text || response.type===this.FormInputType.paragraph) && !response.responseText) {
        response.responseText = data;
      }
      else if(response.type===this.FormInputType.date && !response.responseDate) {
        response.responseDate = data;
      }
      else if(response.type===this.FormInputType.dateTime && !response.responseDateTime) {
        response.responseDateTime = data;
      }
      else if(response.type===this.FormInputType.time && !response.responseTime) {
        let testDate = moment(data);
        if(testDate.isValid()) {
          response.responseTime = testDate.format('HH:mm')
        }
        else {
          response.responseTime = data;
        }
      }
    },
    processData(args) {
      this.userAuth = args.userAuth || {};
      this.extraDataMobile = args.info || {};
      // this.extraData = args.info || {};
      // if(this.links) {
      //   for(const index in this.extraData) {
      //     for(const local of this.links[index] || []) {
      //       if(this.response?.sections?.[local.sectionId]?.items?.[local.itemId]) {
      //         this.tryApplyExtraDataItem(this.response.sections[local.sectionId].items[local.itemId],
      //             this.extraData[index]);
      //         // if(!this.response.sections[local.sectionId].items[local.itemId].responseText) {
      //         //   this.response.sections[local.sectionId].items[local.itemId].responseText = this.extraData[index];
      //         // }
      //       }
      //     }
      //   }
      // }
      // this.response.extraData = this.response.extraData || {};
      // for(const i in this.extraData) {
      //   if(typeof this.extraData[i]==='string' && !this.response.extraData[i]) {
      //     this.response.extraData[i] = this.extraData[i];
      //   }
      // }

      this.response.userLoggedId = this.extraDataMobile['userId'] || this.response.userLoggedId || this.extraData['userId'] || '';
      this.response.userLoggedName = this.extraDataMobile['userName'] || this.response.userLoggedName || this.extraData['userName'] || '';
      this.response.userLoggedCompanyId = this.extraDataMobile['companyId'] || this.response.userLoggedCompanyId || this.extraData['companyId'] || '';
      this.response.companyId = this.response.userLoggedCompanyId;

      FcmOnCall.setAuth(args.userAuth);
    },
    async loadForm() {
      this.isLoading = true;
      this.connectionError = false;
      this.errorMessage = '';
      this.timeOver = false;
      let form = {};
      let response = {
        active: true,
        sections: {},
        formId: '',
        companyId: '',
        formCompanyId: '',
        slug: '',
      };

      if(this.hasDataLoad) {
        console.log('Esperando jwFormDataLoaded');
        await this.promiseDataLoad;
      }

      this.info = await FcmOnCall.callOnCall('forms-getInfo', {
        ...this.extraDataMobile,
        ...this.query,
        slug: this.slug,
        formCompanyId: this.formCompanyId
      });

      console.log('info', this.info);

      this.valueId = this.info?.data?.responseId || this.valueId || null;
      this.logoUrl = this.info?.data?.info?.companyImagesLogo?.url || null;

      let responseData = this.info?.data?.response || null;
      this.extraData = {...this.extraDataMobile, ...this.info?.data?.extraData};
      this.isNew = !responseData;
      if(this.info?.status===490) {
        this.errorMessage = this.$t('formInput.formNotFound');
        this.connectionError = true;
        this.isLoading = false;
        this.$showError({title: this.$t('formInput.errorConnection')});
        return;
      }
      if(this.info?.data?.errorCode===status.apiErrorCode.warningAnswered) {
        this.errorMessage = this.$t('formInput.responseSendPrevious');
      }
      else if(this.info?.data?.errorCode===status.apiErrorCode.OK) {
        if(this.info?.data?.info?.timeOver) {
          this.timeOver = true;
          this.errorMessage = this.$t('formInput.errorTimeOver');
        }
        form = this.info?.data?.form || null;
        this.links = {};

        for(const section of form?.sections || {}) {
          response.sections[section.id] = {
            items: {},
           // name: section.name,
          };
          for(const item of section.items) {
            response.sections[section.id].items[item.id] = {
              type: item.type,
              responseText: '',
              responseOption: null,
              responseOptions: {},
              ...responseData?.sections?.[section.id]?.items?.[item.id],
              // question: item,
            };

            if(item.link && item.link!==this.FormLinkType.none) {
              this.links[item.link] = this.links[item.link] || [];
              this.links[item.link].push({sectionId: section.id, itemId: item.id});
              this.tryApplyExtraDataItem(response.sections[section.id].items[item.id], this.extraData[item.link]);
              // if(this.extraData[item.link] && !response.sections[section.id].items[item.id].responseText) {
              //   response.sections[section.id].items[item.id].responseText = this.extraData[item.link];
              // }
            }
          }
        }
      }
      else {
        console.log('formObjs', this.info);
        this.errorMessage = this.$t('formInput.formNotFound');
        form = {};
      }
      response.formId = form.id || '';
      response.formCompanyId = form.companyId || '';
      response.slug = form.slug || '';
      response.categorySlug = form.categorySlug || '';
      response.version = form.version || 0;
      response.isSuperAdmin = form.isSuperAdmin || false;
      response.extraData = response.extraData || {};
      for(const i in this.extraData) {
        if(typeof this.extraData[i]==='string' && !response.extraData[i]) {
          response.extraData[i] = this.extraData[i];
        }
      }
      response.userLoggedId = this.extraDataMobile['userId'] || response.userLoggedId || this.extraData['userId'] || '';
      response.userLoggedName = this.extraDataMobile['userName'] || response.userLoggedName || this.extraData['userName'] || '';
      response.userLoggedCompanyId = this.extraDataMobile['companyId'] || response.userLoggedCompanyId ||
          this.extraData['companyId'] || response.formCompanyId || '';
      response.companyId = response.userLoggedCompanyId;
      this.response = response;
      this.form = form;
      this.isLoading = false;
      this.emitToMobile('debug', 'formLoaded');
    },
    goBack() {
     // this.$emit('input', null);
    },
    async submitResponse() {
      if (this.$refs.form && !this.$refs.form.validate()) {
        this.$showError({title: this.$t('formInput.errorValidate')});
        return;
      }
      let response = cloneDeep(this.response);
      response.id = this.valueId || null;
      this.tryingToUpdate = true;

      for(const section of this.form.sections) {
        for (const item of section.items) {
          if(response.sections[section.id].items[item.id].responseNumber) {
            response.sections[section.id].items[item.id].responseNumber = parseFloat(response.sections[section.id].items[item.id].responseNumber);
          }
          else if(response.sections[section.id].items[item.id].responseNumber===0) {}
          else {
            response.sections[section.id].items[item.id].responseNumber = null;
          }
        }
      }

      if(this.hasDataLoad) {
        console.log('Esperando jwFormDataLoaded');
        await this.promiseDataLoad;
      }
      try {
        let data = {response};
        if(this.query.fcmDebug) {
          data.fcmDebug = true;
        }
        let ret = await FcmOnCall.callOnCall('forms-setResponse', data);
        if(ret.status===200 && ret.data?.errorCode===status.apiErrorCode.OK) {
          this.$showSuccess();
          this.responseSend = true;
          this.valueId = ret.data?.data?.id;
          this.emitToMobile('success', this.valueId);
          console.log('sucesso', this.valueId);
        }
        else if(ret.status===200 && ret.data?.errorCode===status.apiErrorCode.timeOver) {
          console.error('Erro Tempo permitido para resposta excedido');
          this.$showError({title: this.$t('formInput.errorTimeOver')});
          this.emitToMobile('error', 'Erro Tempo permitido para resposta excedido');
        }
        else if(ret.status===200 && ret.data?.errorCode===status.apiErrorCode.permissionDenied) {
          console.error('errorPermissionDenied');
          this.$showError({title: this.$t('formInput.errorPermissionDenied')});
          this.emitToMobile('error', 'errorPermissionDenied');
        }
        else {
          if(ret.status===490) {
            this.$showError({title: this.$t('formInput.errorConnection')});
            return;
          }
          else {
            console.error('Erro ao gravar resposta com onCall', ret);
            this.$showError({title: this.$t('formInput.errorOnWriteCall')});
            this.emitToMobile('error', 'Erro ao gravar com onCall: '+JSON.stringify(ret));
          }
        }
      } catch(err) {
        console.error('Erro ao gravar resposta', err);
        this.$showError({title: this.$t('formInput.errorOnSave')});
        this.emitToMobile('error', 'Erro ao salvar dados: '+(err?.message || ''));
      }
      this.tryingToUpdate = false;
    },
    async cancel() {
      this.emitToMobile('cancel');
    },
    async testando() {
      this.isLoading = true;
      const formObjs = await FcmOnCall.callOnCall('forms-getForm', {slug: this.slug, companyId: this.companyId});
      console.log('Response', formObjs);
      this.isLoading = false;
    },
    emitToMobile(action, data) {
      if (window?.webkit?.messageHandlers?.formActionsHandler) {
        window.webkit.messageHandlers.formActionsHandler.postMessage({
          action,
          data: data || '',
        });
      }
      if(typeof androidInterface==='object' && typeof androidInterface?.onStatusChanged==='function') {
        androidInterface.onStatusChanged(action, data||'');
      }
    },
    changeOptions(sectionId, itemId) {
      let item = this.response.sections[sectionId].items[itemId];
      if(item.responseOption!=='other' && item.responseText) {
        this.$set(this.response.sections[sectionId].items[itemId], 'responseText', '');
      }
    },
    changeOptionsCheckbox(sectionId, itemId) {
      let item = this.response.sections[sectionId].items[itemId];
      if(!item.responseOption?.other && item.responseText) {
        this.$set(this.response.sections[sectionId].items[itemId], 'responseText', '');
      }
    },
    checkboxRequired(options) {
      for(const i in options) {
        if(options[i]) {
          return true;
        }
      }
      return false;
    },
  }
}
</script>

<template>
  <div class="box mb-10 formResponse">
    <div v-if="isLoading || tryingToUpdate" class="content loading">
      <VProgressCircular indeterminate color="primary" :size="70" :width="7"></VProgressCircular>
    </div>
    <div v-else-if="connectionError" class="content mainMessage">
      {{$t('formInput.errorConnection')}} <br>
      <VBtn class="mr-2 mt-2" @click="loadForm"><VIcon class="mr-2">mdi-refresh</VIcon>{{$t('formInput.tryLoadFormAgain')}}</VBtn>
    </div>
    <div v-else-if="errorMessage && (!timeOver && isNew)" class="content mainMessage">
      {{errorMessage}}
    </div>
    <div v-else-if="responseSend" class="content mainMessage">
      {{$t('formInput.responseSend')}}
    </div>
    <div v-else class="content">
      <div v-if="canEdit && timeOver" class="content mainMessage">
        {{errorMessage}}
      </div>
<!--      <VBtn text class="pl-1" @click.stop="goBack" >-->
<!--        <VIcon x-large left>mdi-chevron-left</VIcon>-->
<!--        {{$t('all.goBack')}}-->
<!--      </VBtn>-->
      <h3 class="header">{{$trdb(form.name)}}</h3>
      <div class="body ck-content" v-html="$trdb(form.body)"></div>
      <VForm ref="form" class="sections" :disabled="!canEdit || timeOver">
        <div v-for="(section, sectionIndex) in form.sections" :key="'section_'+section.id" class="section">
          <div class="sectionContent">
            <div class="sectionHeader">
              {{section.name}}
            </div>
            <div v-for="item in form.sections[sectionIndex].items" :key="'item_'+section.id+'_'+item.id" class="questionResponses">
              <div class="questionResponse">
                <div v-if="item.type===FormInputType.text">
                  <VTextField
                      v-model="response.sections[section.id].items[item.id].responseText"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                  ></VTextField>
                </div>
                <div v-if="item.type===FormInputType.number">
                  <VTextField
                      v-model="response.sections[section.id].items[item.id].responseNumber"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      type="number"
                  ></VTextField>
                </div>
                <div v-if="item.type===FormInputType.paragraph">
                  <VTextarea
                      v-model="response.sections[section.id].items[item.id].responseText"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      outlined
                  ></VTextarea>
                </div>
                <div v-if="item.type===FormInputType.radio">
                  <div class="questionResponseHeader">
                    {{`${item.name} ${item.mandatory?'*':''}`}}
                  </div>
                  <VRadioGroup
                      v-model="response.sections[section.id].items[item.id].responseOption"
                      :rules="item.mandatory?[rules.required]:[]"
                      @change="changeOptions(section.id, item.id)"
                  >
                    <VRadio
                        v-for="option in item.options"
                        :key="'option_'+section.id+'_'+item.id+'_'+option.id"
                        :label="option.name"
                        :value="option.id"
                    ></VRadio>
                    <VRow v-if="item.other" align="center">
                      <VRadio
                          :label="$t('formInput.responseOther')"
                          value="other"
                          class="mr-2 ml-3"
                      ></VRadio>
                      <VTextField
                          v-model="response.sections[section.id].items[item.id].responseText"
                          class="mr-3"
                          :placeholder="$t('formInput.responseOtherTextField')"
                          :disabled="response.sections[section.id].items[item.id].responseOption!=='other'"
                      ></VTextField>
                    </VRow>
                  </VRadioGroup>
                </div>
                <div v-if="item.type===FormInputType.checkbox">
                  <div class="questionResponseHeader">
                    {{`${item.name} ${item.mandatory?'*':''}`}}
                  </div>
                  <VCheckbox
                      v-for="option in item.options"
                      :key="'option_'+section.id+'_'+item.id+'_'+option.id"
                      v-model="response.sections[section.id].items[item.id].responseOptions[option.id]"
                      :label="option.name"
                      hide-details
                      :rules="item.mandatory?[checkboxRequired(response.sections[section.id].items[item.id].responseOptions)]:[]"
                  ></VCheckbox>
                  <VRow v-if="item.other" align="center" class="mt-1">
                    <VCheckbox
                        v-model="response.sections[section.id].items[item.id].responseOptions.other"
                        :label="$t('formInput.responseOther')"
                        class="mr-2 ml-3"
                        :rules="item.mandatory?[checkboxRequired(response.sections[section.id].items[item.id].responseOptions)]:[]"
                        @change="changeOptionsCheckbox(section.id, item.id)"
                    ></VCheckbox>
                    <VTextField
                        v-model="response.sections[section.id].items[item.id].responseText"
                        class="mr-3"
                        :placeholder="$t('formInput.responseOtherTextField')"
                        :disabled="!response.sections[section.id].items[item.id].responseOptions.other"
                    ></VTextField>
                  </VRow>
                </div>
                <div v-if="item.type===FormInputType.select">
                  <VSelect
                      v-model="response.sections[section.id].items[item.id].responseOption"
                      :items="item.options"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      item-text="name"
                      item-value="id"
                  ></VSelect>
                </div>
                <div v-if="item.type===FormInputType.date">
                  <FcmDate
                      v-model="response.sections[section.id].items[item.id].responseDate"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      type="iso"
                  ></FcmDate>
                </div>
                <div v-if="item.type===FormInputType.dateTime">
                  <FcmDate
                      v-model="response.sections[section.id].items[item.id].responseDateTime"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                      type="iso"
                      pick-time
                      format="DD/MM/YYYY HH:mm"
                      mask="##/##/#### ##:##"
                  ></FcmDate>
                </div>
                <div v-if="item.type===FormInputType.time">
                  <FcmTimer
                      v-model="response.sections[section.id].items[item.id].responseTime"
                      :label="`${item.name} ${item.mandatory?'*':''}`"
                      :rules="item.mandatory?[rules.required]:[]"
                  ></FcmTimer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VForm>
      <div class="actions">
        <VBtn class="mr-2" :disabled="!canEdit || tryingToUpdate" @click="submitResponse">{{$t('formInput.submitResponse')}}</VBtn>
        <VBtn class="mr-2" @click="cancel">{{$t('formInput.cancel')}}</VBtn>
      </div>
    </div>
    <div class="version">
      {{version}}
    </div>

  </div>
</template>

<style lang="scss">

.formResponse {
  .v-label {
    color: var(--v-text-base)!important;
  }
  .theme--dark.v-label {
    color: var(--v-text-base)!important;
  }
}

</style>

<style lang="scss" scoped>
// @import '@design';

.box {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .loading {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .version {
    color: #5a5a5a;
    padding: 45px 20px 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    justify-self: flex-end;
    flex: 1;
  }

  .mainMessage {
    padding: 20px;
  }

  .content {
    max-width: 900px;
    width: 80%;
    @media screen and (max-width: 920px) {
      width: 95%;
    }
    .header {
      margin-top: 15px;
    }
    .body {
      //::v-deep img {
      //  max-width: 100%;
      //  max-height: 100%;
      //}
    }
    .sections {
      .section {
        min-height: 80px;
        border-radius: 7px;
        background-color: var(--v-modalBg-base)!important;
        padding: 10px;
        margin-bottom: 10px;
        .sectionContent {
          .sectionHeader {

          }
          .questionResponses {
            .questionResponse {
              margin: 0 0 7px;
              padding: 10px 10px 10px;
              min-height: 30px;
              background-color: var(--v-listItemBg-base)!important;
              border-radius: 7px;

              .questionResponseHeader {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .actions {

    }
  }
}
</style>





















