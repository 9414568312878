"use strict";

let toExport = {};

if(!process.env.GCLOUD_PROJECT_ON_BUILD && process.env.GCLOUD_PROJECT) {
  process.env.GCLOUD_PROJECT_ON_BUILD = process.env.GCLOUD_PROJECT;
}

let links = {
  site: {
    name: 'Site',
    link: 'https://www.volan.app.br/'
  },
  /* facebook: {
     name: 'Facebook',
     link: 'https://www.facebook.com/Sauver-112736837169018'
   },
   instagram: {
     name: 'Instagram',
     link: 'https://www.instagram.com/sauver_med'
   } */
};


const android = {
  linkStore: 'https://play.google.com/store/apps/developer?id=JustWorks',
  storeCode: '',
  id: 'br.com.justworks.jw-vue-boilerplate',
  uri: 'vnd.jw_vue_boilerplate://',
  name: 'Volan',
  packageName: 'br.com.justworks.jw-vue-boilerplate'
};

const ios = {
  linkStore: 'https://apps.apple.com/br/developer/alexandre-reis-corr%C3%AAa-cruz/id429125678',
  storeCode: 'id15168907wer',
  id: 'id15168907we',
  uri: 'vnd.jw_vue_boilerplate://',
  name: 'Volan',
  packageName: '',
};

toExport.listConfig = {
  'default': {
    configId: 'defaultVolan',
    name: 'Default ALGO DE ERRADO NÃO ESTA CERTO',
    firebase: null,
    fbId: '',
    title: 'Volan'+(!(process.env.NODE_ENV === 'production' || process.env.VUE_APP_DEVELOPER_DATABASE_MODE === 'production')?' DEV ':''),
    description: 'Volan!',

    baseUrl: 'https://forms.volan.app.br',
    baseHomeUrl: 'https://volan.app.br',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#4DBA87',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'volan.app.br',
    region: 'southamerica-east1',
    isDevServer: true,
    functionsUrl: 'https://southamerica-east1-volanjwcg.cloudfunctions.net',
  },
  'volanjwcg': {
    configId: 'volanjwcgVolan',
    name: 'volan PRODUCTION',
    maps: {
      key: 'AIzaSyAjSPrpte94NNTEnfqToUNhczN0RsVe1M4'
    },
    title: 'Volan',
    description: 'Volan!',

    baseUrl: 'https://forms.volan.app.br',
    baseHomeUrl: 'https://volan.app.br',
    firebase: {
      apiKey: "AIzaSyAKxb1IRByUO_ycdklAOtDQ4FL4epbaoiU",
      authDomain: "volanjwcg.firebaseapp.com",
      databaseURL: "https://volanjwcg.firebaseio.com",
      projectId: "volanjwcg",
      storageBucket: "volanjwcg.appspot.com",
      messagingSenderId: "326211280257",
      appId: "1:326211280257:web:d463dddfae12f3e5d89c80",
      measurementId: "G-5DGTSM0KB7"
    },
    functionsUrl: 'https://southamerica-east1-volanjwcg.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#000000',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'yes',
    appleMobileWebAppStatusBarStyle: 'black',
    emailKey: 'volan.app.br',
    region: 'southamerica-east1',
    isDevServer: false,
  },
  'developer': {
    configId: 'developerVolan',
    name: 'volandevjw DEVELOPER',
    maps: {
      key: 'AIzaSyAjSPrpte94NNTEnfqToUNhczN0RsVe1M4'
    },
    title: 'Volan DEV ',
    description: 'Volan!',

    baseUrl: 'https://formsdev.volan.app.br',
    baseHomeUrl: 'https://dev.volan.app.br',
    firebase: {
      apiKey: "AIzaSyD7LGfB5VoQsJmuI8NXPdWTSoEInb6a0F4",
      authDomain: "volandevjw.firebaseapp.com",
      databaseURL: "https://volandevjw-default-rtdb.firebaseio.com",
      projectId: "volandevjw",
      storageBucket: "volandevjw.appspot.com",
      messagingSenderId: "383344551084",
      appId: "1:383344551084:web:b466e9d01ff6aa1dffeb09",
      measurementId: "G-JYWS016PJZ"
    },
    functionsUrl: 'https://southamerica-east1-volandevjw.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#4DBA87',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'volan.app.br',
    region: 'southamerica-east1',
    isDevServer: true,
  },
  'volandevjw': {
    configId: 'volandevjwVolan',
    name: 'volandevjw DEVELOPER',
    maps: {
      key: 'AIzaSyAjSPrpte94NNTEnfqToUNhczN0RsVe1M4'
    },
    title: 'volandevjw DEV ',
    description: 'Volan!',

    baseUrl: 'https://formsdev.volan.app.br',
    baseHomeUrl: 'https://dev.volan.app.br',
    firebase: {
      apiKey: "AIzaSyD7LGfB5VoQsJmuI8NXPdWTSoEInb6a0F4",
      authDomain: "volandevjw.firebaseapp.com",
      databaseURL: "https://volandevjw-default-rtdb.firebaseio.com",
      projectId: "volandevjw",
      storageBucket: "volandevjw.appspot.com",
      messagingSenderId: "383344551084",
      appId: "1:383344551084:web:b466e9d01ff6aa1dffeb09",
      measurementId: "G-JYWS016PJZ"
    },
    functionsUrl: 'https://southamerica-east1-volandevjw.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#4DBA87',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'volan.app.br',
    region: 'southamerica-east1',
    isDevServer: true,
  },
  'volaninternal': {
    configId: 'volaninternalVolan',
    name: 'volaninternal DEVELOPER',
    maps: {
      key: 'AIzaSyAjSPrpte94NNTEnfqToUNhczN0RsVe1M4'
    },
    title: 'volaninternal DEV ',
    description: 'Volan!',

    baseUrl: 'https://forms.volan.app.br',
    baseHomeUrl: 'https://volan.app.br',
    firebase: {
      apiKey: "AIzaSyC24qNiBihv39I1C-SZom_VJ9oj3mWqZDk",
      authDomain: "volaninternal.firebaseapp.com",
      databaseURL: "https://volaninternal-default-rtdb.firebaseio.com",
      projectId: "volaninternal",
      storageBucket: "volaninternal.appspot.com",
      messagingSenderId: "905176493683",
      appId: "1:905176493683:web:44adfc55a9323aa24c4406"
    },
    functionsUrl: 'https://southamerica-east1-volaninternal.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#4DBA87',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'volan.app.br',
    region: 'southamerica-east1',
    isDevServer: true,
  }
};
toExport.config = toExport.listConfig['default'];

if(process.env.GCLOUD_PROJECT_ON_BUILD==='volandevjw') {
  toExport.config = toExport.listConfig['volandevjw'];
  console.log('Server config start configName: ' + toExport.config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}
else if (process.env.GCLOUD_PROJECT_ON_BUILD==='volanjwcg') {
  toExport.config = toExport.listConfig['volanjwcg'];
  console.log('Server config start configName: ' + toExport.config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}
else {
  toExport.config = toExport.listConfig['developer'];
  console.log('Server config start configName: ' + toExport.config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}

// console.log('Server config start configName: ' + toExport.config.name);

toExport.appConfig = toExport.config;

module.exports = toExport;
