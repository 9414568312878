<script>
import 'vuetify/dist/vuetify.min.css';
import '@src/design/ck-editor-content-styles.css';
import JwFomResponseItem from "@components/JwFomResponseItem";
import {appConfig} from '@src/config';

export default {
  name: 'App',

  components: {
    JwFomResponseItem
  },

  data: () => ({
    logoUrl: null,
    companyName: null,
    isLoading: true,
    companyId: true,
  }),
  methods: {
    goHome() {
      document.location.href = appConfig.baseHomeUrl;
    },
    changeCompanyInfo(info) {
      this.logoUrl = info?.images?.logoForm?.url || null;
      this.companyName = info?.name || null;
      this.companyId = info?.id || null;
    },
    finishLoading() {
      this.isLoading = false;
    }
  }
};
</script>

<template>
  <VApp class="app-page">
    <div class="brandBox">
      <div class="brandContainer">
        <div class="logo">
          <img v-if="logoUrl && !isLoading" class="imgBrand jwCursorPointer" :src="logoUrl" alt="" @click="goHome()">
          <img v-else-if="companyId==='volan' && !isLoading" class="imgBrand jwCursorPointer" :src="require('@assets/images/logo-main.png')" alt="" @click="goHome()">
          <div v-else class="imgBrand jwCursorPointer"  alt="" @click="goHome()"></div>
        </div>
        <div v-if="companyId!=='volan' && !isLoading" class="textContainer">
          <div class="companyName">{{companyName}}</div>
          <div class="poweredBy">
            <img class="poweredByImg jwCursorPointer" :src="require('@assets/images/poweredbyvolan@3x.png')" alt="" @click="goHome()">
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <JwFomResponseItem
          @changeCompanyInfo="changeCompanyInfo"
          @finishLoading="finishLoading"
      ></JwFomResponseItem>
    </div>
  </VApp>
</template>

<style lang="scss">

html {
  overflow-y: auto;
  background-color: black !important;
}

body {
  background-color: black !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--v-scroll-base, #5a5a5a) var(--v-scrollBg-base, #121212);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: var(--v-scrollBg-base, #121212);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--v-scroll-base, #5a5a5a);
  border-radius: 20px;
  // border: 3px solid #121212;
}


.jwCursorPointer {
  cursor: pointer;
}

</style>


<style lang="scss" scoped>
.content {
  min-height: calc(100% - 280px);
}
.app-page {
  background-color: black !important;
  color: white;
  height: 100%;
  width: 100%;
  font-family: 'Sora-Regular', sans-serif !important;
  font-size: 20px;
}

.brandBox {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  .brandContainer {
    height: 270px;

    max-width: 900px;
    width: 80%;
    @media screen and (max-width: 920px) {
      width: 95%;
    }

    @media screen and (max-width: 700px) {
      height: 209px;
    }
    @media screen and (max-width: 420px) {
      height: 160px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      margin: 0px 23px 0px 13px;
      .imgBrand {
        height: 180px;
        width: 180px;
        object-fit: cover;
        border-radius: 50%;
        @media screen and (max-width: 700px) {
          height: 126px;
          width: 126px;
        }
        @media screen and (max-width: 420px) {
          height: 80px;
          width: 80px;
        }
      }
    }
    .textContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .companyName {
        font-size: 24px;
        font-weight: 500;
        @media screen and (max-width: 420px) {
          font-size: 19px;
        }
      }
      .poweredBy {
        .poweredByImg {
          max-width: 188px;
          @media screen and (max-width: 420px) {
            max-width: 150px;
          }
        }
      }
    }
  }
}

</style>

















